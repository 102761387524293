import React, { Component } from "react";
import * as API from "API";
import PageHeader from "components/page-header/PageHeader";
import EntityList from "components/entity-list/EntityList";
import { COMPETITIONS_CONTENT as PAGE_CONTENT } from "libs/content";
import "./Competitions.css";

export default class Competitions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      competitions: []
    };
  }

  componentDidMount = async () => {
    this.fetchCompetitions();
  };

  fetchCompetitions = async (year) => {
    try {
      this.props.setLoading(true);
      const results = await API.competitionsLite( 
      );
      this.props.setLoading(false);
      this.setState({ competitions: results });
    } catch (e) {
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  displayHeader = (competition) => {
    return competition.compName;
  };

  displayText = (competition) => {
    return (
      competition.dateFrom +
      (competition.dateTo ? " - " + competition.dateTo : "")
    );
  };

  formattedURL = (id) => {
    return "/competition/summary/" + id;
  };

  render = () => (
    <div className="Competitions">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <EntityList
        {...this.props}
        isLoading={this.state.isLoading}
        listItems={this.state.competitions}
        type="competition"
        header={this.displayHeader}
        text={this.displayText}
        hideAdd={this.props.isAdmin ? null : true}
        formattedURL={this.props.isAdmin ? null : this.formattedURL}
      />
    </div>
  );
}
