import React, { Component } from "react";
import * as API from "API";
import { Glyphicon } from "react-bootstrap";
import PageHeader from "components/page-header/PageHeader";
import EntityList from "components/entity-list/EntityList";
import { ROUNDS_CONTENT as PAGE_CONTENT } from "libs/content";
import "./Rounds.css";

export default class Rounds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      rounds: []
    };
  }

  componentDidMount = async () => {
    this.fetchRounds();
  };

  fetchRounds = async (year) => {
    try {
      this.props.setLoading(true);
      const results = await API.roundsLite(
      );
      this.props.setLoading(false);
      this.setState({ rounds: results });
    } catch (e) {
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  displayHeader = (round) => {
    return round.courseName ? round.courseName : "";
  };

  displayText = (round) => (
    <span>
      {round.roundDate}{" "}
      {round.complete ? (
        <span className="round-complete text-muted">
          <Glyphicon glyph="ok" className="text-muted" /> Complete
        </span>
      ) : null}
    </span>
  );

  isDisabled = (isAdmin, round) => {
    return !isAdmin && !round.complete ? true : false;
  };

  formattedURL = (id) => {
    return "/round/summary/" + id;
  };

  render = () => (
    <div className="Rounds">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <EntityList
        {...this.props}
        isLoading={this.state.isLoading}
        listItems={this.state.rounds}
        type="round"
        header={this.displayHeader}
        text={this.displayText}
        hideAdd={this.props.isAdmin ? null : true}
        formattedURL={this.props.isAdmin ? null : this.formattedURL}
        isAdmin={this.props.isAdmin}
        dis={this.isDisabled}
      />
    </div>
  );
}
